// extracted by mini-css-extract-plugin
export const container = "blogPost-module--container--Fkhys";
export const wrapper = "blogPost-module--wrapper--rmDPH";
export const row = "blogPost-module--row--1Gn9a";
export const article = "blogPost-module--article--QJZxJ";
export const title = "blogPost-module--title--2QmeV";
export const postImage = "blogPost-module--postImage--hjTgp";
export const postImageItem = "blogPost-module--postImageItem--1C9J7";
export const postContent = "blogPost-module--postContent--2qU78";
export const postMeta = "blogPost-module--postMeta--NfBN6";
export const meta = "blogPost-module--meta--2BgDk";
export const share = "blogPost-module--share--3wlWF";
export const author = "blogPost-module--author--nqE5Y";
export const date = "blogPost-module--date--3dV9D";
export const category = "blogPost-module--category--2_0ka";
export const shareTitle = "blogPost-module--shareTitle--SMvPo";
export const shareIcons = "blogPost-module--shareIcons--1bShK";
export const awesomeIcons = "blogPost-module--awesomeIcons--3TuPo";
export const relatedPosts = "blogPost-module--relatedPosts--38yrm";
export const titleLink = "blogPost-module--titleLink--3cT8u";
export const grid = "blogPost-module--grid--1Efwk";
export const postItem = "blogPost-module--postItem--2uw3K";
export const postItemImage = "blogPost-module--postItemImage--3Y86C";
export const innerBox = "blogPost-module--innerBox--3W_1v";
export const relatedTitle = "blogPost-module--relatedTitle--2FhP1";
export const relatedDate = "blogPost-module--relatedDate--3xFOu";